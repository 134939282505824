import { Link } from "react-router-dom";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <Link to="/" class="back-button">
        &lt; Back{" "}
      </Link>
      <h1 class="centered">Privacy Policy as of 3/14/24</h1>
      <h2>I. Collection of Data</h2>
      <h4 class="indented-1">A. Account Creation and Information</h4>
      <p class="centered">
        This is not a social application, and there is no need for your personal
        information to be tied to your links that you keep in your Hero App, so
        we do not collect any personal information. When signing up for Hero the
        first time, you are able to choose whether you share your email with us
        or not. Choosing to share gives us access to your email, while choosing
        to hide your email provides us with an email generated by Apple. If we
        send mail to the generated email, then Apple will forward the mail to
        your email address. When you sign up to use the Hero application, a
        unique user ID is created, and no personal information (name, birthday,
        mother’s maiden name, social security) is saved to our database. We want
        our users to feel secure using Hero, knowing that the guilty cat videos
        you save in your app will not find their way to your Facebook page, as
        there is no way for us to determine who you are.
      </p>

      <h2>II. Use of Collected Data</h2>
      <p class="centered">
        All of the data we collect related to your Hero is used to provide the
        best user experience to you and other users of our application.
      </p>
      <h4 class="indented-1">A. Links</h4>
      <p class="centered">
        When you save a link to Hero, we collect data about each link like URLs,
        images, and titles. This allows us to display the specific contents of
        each link in our smart previews, as well as the image in the details
        page, and lets you search by title of articles, for example. This is
        also useful for us so that we can see what types of links people are
        saving to the app and will be important for future features we plan to
        implement.
      </p>
      <h4 class="indented-1">B. Analytics</h4>
      <p class="centered">
        Hero uses analytics to see which features of the app are most frequently
        used. This lets us know where we can make improvements and how to
        continue to develop the features that we believe will bring the most
        value to our end users. This data is not tied to your specific account.
      </p>

      <h2>
        III. How users can request data be turned over to them or deleted:
      </h2>
      <p class="centered">
        Hero is here to save the day and make you feel safe. If you wish to have
        your data turned over to you, please reach out to us at
        hello@corner.solutions and we will be able to provide you with a form to
        complete in order for us to find the data associated with you. Remember,
        we don’t know who you are, so we will have to request some information
        to link you to the random username that you go by in the Hero universe.
        When we turn your data over to you, you will receive all of the data
        associated with your personal Hero. This will include the links you have
        saved, and data associated with those links, such as tags. If you would
        like your data deleted, you can delete your account through the
        application by navigating to the bottom of the settings page.
        Additionally, you can reach out at hello@corner.solutions, and
        everything associated with your account will disappear. Just to be
        clear, once we delete your data, we will not be able to retrieve it
        again. This means that all the links you have saved and the information
        about those links will be gone, so we would not be able to turn over
        your data to you after deleting your account, and if you log back into
        Hero with the same credentials, it will be blank, and as if you signed
        up for the first time.
      </p>

      <h2>IV. 3rd Party Dependencies</h2>
      <p class="centered">
        A number of 3rd parties and open source software were used to develop
        Hero. To see a list of these dependencies you can navigate to the
        settings page within the app. These dependencies are commonly used
        providers that have been thoroughly checked for their safety. It is
        still possible that a data breach could occur through one of these
        dependencies, but at the moment there shouldn’t be anything to worry
        about because there is no information that would be tied to you as an
        individual.
      </p>

      <h2>V. Contact Information:</h2>
      <p class="centered">
        If there are any questions or concerns about this Privacy Policy or you
        would just like to learn more about how it works, feel free to contact
        Corner Solutions® LLC by email at hello@corner.solutions.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
