import React from "react";
import "./MainPage.css";
import { Link } from "react-router-dom";
import logo from "../../assets/icon.png";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import "../../components/Text/Text.css";

import linkPreviewsImage from "../../assets/link_previews.png";
import linkPreviewsImageMobile from "../../assets/mobile_link_previews.png";

const DOWNLOAD_LINK =
  "https://apps.apple.com/us/app/hero-save-the-internet/id1589862154";

const MainPage = (props) => {
  const Header = () => {
    return (
      <header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 0rem 2rem 0rem",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            alt="Hero logo"
            style={{
              maxHeight: "56px",
              width: "auto",
              height: "auto",
              paddingRight: "0.5rem",
            }}
          />
          <Text
            textStyle="largeTitle"
            style={{
              fontWeight: "bold",
              fontSize: "64px",
              color: "var(--accent-color)",
            }}
          >
            Hero
          </Text>
        </div>
      </header>
    );
  };

  const Footer = () => {
    let footerLinkStyle = {
      padding: "8px",
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link to="/privacy" class="footer-link" style={footerLinkStyle}>
            <Text textStyle="body">Privacy</Text>
          </Link>
          <Link to="/terms" class="footer-link" style={footerLinkStyle}>
            <Text textStyle="body">Terms</Text>
          </Link>
        </div>
        <div>
          <Link to="/faqs" class="footer-link" style={footerLinkStyle}>
            <Text textStyle="body">FAQs</Text>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="mainPage" style={{ height: "100%" }}>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          flexGrow: "1",
          gap: "64px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Text textStyle="largeTitle">The best way to save the internet</Text>
          <Text
            textStyle="headline"
            style={{
              color: "var(--tertiary-text-color)",
            }}
          >
            a bookmarking app for everything you love
          </Text>
        </div>
        <Button
          onClick={() => {
            window.open(DOWNLOAD_LINK);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "16px 24px",
            }}
          >
            <Text
              textStyle="title3"
              style={{
                fontWeight: "800",
                color: "var(--accent-color)",
              }}
            >
              Download
            </Text>
          </div>
        </Button>
        <img
          src={
            window.innerWidth > 1000
              ? linkPreviewsImage
              : linkPreviewsImageMobile
          }
          width="100%"
        />
      </div>
      <Footer style={{ flexShrink: "0" }} />
    </div>
  );
};

export default MainPage;
