import "./FAQs.css";
import { Link } from "react-router-dom";

const FAQs = () => {
  return (
    <div>
      <Link to="/" class="back-button">
        &lt; Back
      </Link>
      <h1 class="centered">FAQs</h1>

      <h4>1. How many links can I save with Hero?</h4>
      <p>
        Since Hero is here to help you save the internet, right now there is no
        limit to how many links you can save with Hero. The more you save, the
        better we can cater the app to provide the best experience possible for
        the type of content you will see when you use the app.
      </p>

      <h4>2. What types of links can I save with Hero?</h4>
      <p>
        Hero is intended to be used to save whatever links are important to you.
        This can be internet content you constantly reference, or webpages you
        never want to lose. However, as mentioned in our Terms of Service, by
        using Hero you agree not to use the App for any illegal or unauthorized
        purpose and to comply with all applicable laws and regulations.
      </p>

      <h4>3. Can I access Hero from my computer?</h4>
      <p>
        While we would love to provide a web application to access your Hero
        collection, we are a small team, so unfortunately for right now there is
        no way to view your bookmarks if you are not on a mobile device.
        However, we have a Chrome browser extension that only allows you to send
        your bookmarks to Hero from a Chrome browser so the next time you open
        Hero on your phone, you will see the links you saved from the computer.
      </p>

      <h4>
        4. Copy and pasting links into Hero is too cumbersome, can I share from
        my browser on my phone?
      </h4>
      <p>
        Yes, to add Hero to your share extension options on your mobile device
        follow the following steps:
      </p>
      <ol>
        <li>Open Safari on your iPhone, iPad or iPod touch</li>
        <li>Tap the Share icon</li>
        <li>
          Swipe the row with the app icons to the right, and tap More (white
          block with 3 dots)
        </li>
        <li>Tap Edit</li>
        <li>
          Find Hero and add it to your favorites list by tapping the + button
        </li>
        <li>Move Hero to the top of the list (optional)</li>
        <li>Tap Done</li>
      </ol>
      <p>
        <b>
          If you follow these steps but don't see Hero in your list of apps,
          please restart your phone and try again.
        </b>
      </p>

      <h4>
        5. I have noticed that when I am offline, I cannot access my account. Is
        there offline mode for Hero or a way for me to download links or
        articles?
      </h4>
      <p>
        Unfortunately, no. If you would really like to see this feature, let us
        know at hello@corner.solutions.
      </p>

      <h4>6. Is there a way for me to recommend features for Hero?</h4>
      <p>
        Yes, we would love to hear what features you want to make the app more
        enjoyable and useful for you. You can reach us at
        hello@corner.solutions.
      </p>

      <h4>7. How can I support Hero’s development?</h4>
      <p>
        Thanks for asking! The easiest (free) way to support the app would be to
        leave us a 5 star review on the App Store. If you want to monetarily
        support Hero, you can venmo @Chad-Nislow whatever you feel like we
        deserve. And we will make sure this is split among the developers and
        used to purchase items like coffee and candy otherwise known as fuel for
        coding :)
      </p>
    </div>
  );
};

export default FAQs;
