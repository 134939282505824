import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import Terms from './screens/Terms/Terms';
import FAQs from './screens/FAQs/FAQs';
import MainPage from './screens/MainPage/MainPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage/>
  },
  {
    path: "privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "faqs",
    element: <FAQs />,
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
