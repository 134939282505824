import React from "react";
import './Text.css';

export const Text = ({ textStyle, children, style }) => {
    return (
        <span
            className={`text ${textStyle}`}
            style={style}
        >
            {children}
        </span>
    )
};