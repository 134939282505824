import "./Terms.css"
import { Link } from "react-router-dom";

const Terms = () => {
    return (
        <div>
            <Link to="/" class="back-button">&lt; Back</Link>
            <h1 class="centered">Hero App Terms of Service</h1>

            <p class="centered">
                Thank you for using Hero App. These Terms of Service (the "Terms") outline the terms and conditions that govern your use of the App. Please read these Terms carefully before using the App. By downloading, accessing, or using Hero App, you agree to be bound by these Terms.
            </p>

            <h4>Acceptance of Terms</h4>
            <p>
                By downloading, accessing, or using the App, you affirm that you are of legal age and have the capacity to enter into these Terms. If you do not agree with any part of these Terms, you may not use the App.
            </p>

            <h4>User Content</h4>
            <p >
                The App allows you to save, tag, and search links for your personal use. You are solely responsible for the content of the links you save using the App. You agree not to use the App for any illegal or unauthorized purpose and to comply with all applicable laws and regulations.
            </p>

            <h4>Privacy</h4>
            <p >
                Here at Hero, we respect your privacy and are committed to protecting your personal information. Our privacy practices are outlined in our Privacy Policy, which is incorporated into these Terms by reference. By using Hero, you consent to our collection, use, and disclosure of personal information as described in the Privacy Policy, which is none considering we don’t even know your name.
            </p>

            <h4>Hero Accounts</h4>
            <p >
                As outlined in the Privacy Policy, by signing up for the app, a user creates an account and can begin using Hero immediately. No personal information is collected, because we don’t need it to provide you with a first-class bookmarking service.
            </p>

            <h4>Intellectual Property</h4>
            <p >
                Hero App and all its contents, including but not limited to text, graphics, logos, images, and software, are the intellectual property of Corner Solutions LLC and are protected by copyright and other intellectual property laws. You may not modify, reproduce, distribute, create derivative works, or exploit any part of Hero without our prior written consent.
            </p>

            <h4>Limitation of Liability</h4>
            <p >
                To the fullest extent permitted by law, Corner Solutions LLC and its affiliates, officers, directors, employees, agents, and partners shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising from or in connection with the use of the Hero.
            </p>

            <h4>Modifications to the App</h4>
            <p >
                Hero reserves the right to modify or discontinue, temporarily or permanently, the App or any part thereof, with or without notice. You agree that Hero shall not be liable to you or any third party for any modification, suspension, or discontinuance of the App.
            </p>

            <h4>Governing Law and Jurisdiction</h4>
            <p >
                These Terms shall be governed by and construed in accordance with the laws of the United States of America (USA). Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of the USA.
            </p>

            <h4>Severability</h4>
            <p >
                If any provision of these Terms is held to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not in any way be affected or impaired.
            </p>

            <h4>Entire Agreement</h4>
            <p >
                These Terms constitute the entire agreement between you and Hero App regarding the use of the App and supersede all prior agreements and understandings, whether oral or written.
            </p>

            <h4>Contact Information</h4>
            <p >
                If you have any questions, concerns, or feedback regarding these Terms, please contact us by email at hello@corner.solutions
            </p>
        </div>
    )
}

export default Terms;